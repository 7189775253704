* {
    margin:0;
    padding:0;
    font-family: Noto Sans TC, sans-serif;
    
}
.App {
    background-color: #ecefff;
}
.container {
    align-items: center;

    padding: {
        left:40px;
        right:40px;
        top:50px;
        bottom:50px;
    }

}
.intro_container {
    width: 75%;
    padding:40px;
    margin:0 auto;
    background-color: #313237;
    color: #fff;
    margin-top: 50px;
    border-radius: 50px;
    line-height: 2;
    display: block;
    box-shadow: 0px 0px 15px rgba(102, 220, 87, 0.5);
    p {
        font-size: 20px;
        letter-spacing: 2px;
        margin: 30px;
    }  
    h1 {
        line-height: 1;
        margin-left: 1rem;
    }

    @media screen and (max-width: 768px) {
        padding: 10px;
        padding-top:40px;
        box-sizing: border-box;
        width:90%;
        border-radius: 25px;
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
            letter-spacing: 1px;
            margin: 20px;
        }
        
    }
}
.nav_menu_list{
    background-color: #3e8550;
    display: flex;
    position: absolute;
    flex-direction: column;
    right: -0.5rem;
    top: 4rem;
    word-break: keep-all;
    border-radius: 15px;
    .nav_menu_item{
        font-size: 20px;
        color: #fff;
        text-decoration: none;
        padding: {
            left:20px;
            right:20px;
            top:10px;
            bottom: 10px;
        }
        box-sizing: border-box;
        border-bottom: #254f2f 1px solid;
        &:hover{
            color: #3e8550;
        }

    }
}